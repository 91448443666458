import React, { useEffect } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";

const ReviewDetails = ({ modal, closeModal }) => {
  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <div class="modal-body modal-body-md">
            <form action="#" class="mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Name : </label>
                    <span> Abu Bin Ishtiyak</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Email : </label>
                    <span> abubinishtiyak@gmail.com</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Hospital : </label>
                    <span> Danat Al Emarat Hospital For Women & Children</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Department : </label>
                    <span> Cardiology</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Subject :</label>
                    <span>
                      {" "}
                      I am facing problem as i can not select currency on buy order page. Can you guys let me know what
                      i am doing doing wrong? Please check attached files.
                    </span>
                  </div>
                </div>
              </div>
              <div class="nk-reply-meta mt-4">
                <div class="nk-reply-meta-info">
                  Send by : <span class="whom">Abu Bin Ishtiyak</span> at 14 Jan, 2020 at 11:34 AM
                </div>
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ReviewDetails;
