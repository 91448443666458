import React from "react";
import { Card, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon, Col } from "../../../Component";
import avatar1 from "../../../../images/avatar/b-sm.jpg";
import avatar2 from "../../../../images/avatar/c-sm.jpg";
import avatar3 from "../../../../images/avatar/d-sm.jpg";
import avatar4 from "../../../../images/avatar/e-sm.jpg";
import avatar5 from "../../../../images/avatar/f-sm.jpg";
import avatar6 from "../../../../images/avatar/g-sm.jpg";
const SocialMediaFeed = () => {
  return (
    <div className="row">
      <Col xxl="6" md="6">
        <div className="card mb-3 shadow-sm">
          {/* <div className="card-header bg-white border-bottom-0"> */}
          <h6 className="card-title m-3">Instagram</h6>
          {/* </div> */}
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="card border-0">
                  <img src={avatar1} className="card-img-top" alt="Image 1" />
                  <div className="card-body p-2">
                    <p className="card-text mb-1">Beautiful staircase </p>
                    <p className="small text-muted">Vivamus in justo non ipsum auctor vulputate a sed turpis.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card border-0">
                  <img src={avatar2} className="card-img-top" alt="Image 2" />
                  <div className="card-body p-2">
                    <p className="card-text mb-1">Minimalist dining area.</p>
                    <p className="small text-muted">Vivamus in justo non ipsum auctor vulputate a sed turpis.</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="card border-0">
                  <img src={avatar3} className="card-img-top" alt="Image 3" />
                  <div className="card-body p-2">
                    <p className="card-text mb-1">Cozy living room setup.</p>
                    <p className="small text-muted">Vivamus in justo non ipsum auctor vulputate a sed turpis.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Col>

      <Col xxl="6" md="6">
        <div className="card mb-3 shadow-sm">
          <h6 className="card-title m-3">Twitter</h6>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="card border-0">
                  <img src={avatar4} className="card-img-top" alt="Image 1" />
                  <div className="card-body p-2">
                    <p className="card-text mb-1">Beautiful staircase </p>
                    <p className="small text-muted">Vivamus in justo non ipsum auctor vulputate a sed turpis.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card border-0">
                  <img src={avatar5} className="card-img-top" alt="Image 2" />
                  <div className="card-body p-2">
                    <p className="card-text mb-1">Minimalist dining area.</p>
                    <p className="small text-muted">Vivamus in justo non ipsum auctor vulputate a sed turpis.</p>
                  </div>
                </div>
              </div>
              {/*  <div className="col-md-4">
                <div className="card border-0">
                  <img src={avatar6} className="card-img-top" alt="Image 3" />
                  <div className="card-body p-2">
                    <p className="card-text mb-1">Cozy living room setup.</p>
                    <p className="small text-muted">Vivamus in justo non ipsum auctor vulputate a sed turpis.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default SocialMediaFeed;
