import React, { useContext, useEffect, useState } from "react";
import { Card, Badge } from "reactstrap";
import { Button } from "../../../Component";
import axios from "axios";
import EditFacility from "../../../popup/EditFacility";
import {getCurrentUser} from "../../../../services/auth"

const ActiveCampaign = () => {
  const [hospitalData, setHospitalData] = useState("");
  const [facilityEdit, SetFacilityEdit] = useState("");
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);

  const editFacility = (id) => {
    SetFacilityEdit(id);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      localStorage.setItem("user", JSON.stringify(currentUser));
    } else {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, []);

  const getHospitalList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    if (user) {
      const USR_MAIL = user.uid;
      axios
        .get(
          `https://api.hospitalsinuae.com/api/resource/Facility?fields=["name","facility_name","country","location","facility_type","emirate","status"]&filters=[["owner","=","${USR_MAIL}"]]`,
          {
            headers: { Authorization: USR_TOKEN, "content-type": "application/json" },
          }
        )
        .then((response) => {
          setHospitalData(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      getHospitalList();
    }
  }, [user]);

  return (
    <>
      <Card className="card-full">
        <div className="card-inner">
          <div className="card-title-group">
            <div className="card-title">
              <h6 className="title">Active Hospitals</h6>
            </div>
            <div className="card-tools">
              <a href="/facilities" className="link">
                View All
              </a>
            </div>
          </div>
        </div>
        <div className="card-inner py-0 mt-n2">
          <div className="nk-tb-list nk-tb-flush nk-tb-dashed">
            <div className="nk-tb-item nk-tb-head">
              <div className="nk-tb-col">
                <span>Facility</span>
              </div>
              <div className="nk-tb-col tb-col-mb">
                <span>Channels</span>
              </div>
              <div className="nk-tb-col tb-col-sm">
                <span>Status</span>
              </div>
              <div className="nk-tb-col tb-col-md">
                <span>Assignee</span>
              </div>
              {/* <div className="nk-tb-col text-end">
                <span>Actions</span>
              </div> */}
            </div>
            {hospitalData.length > 0 &&
              hospitalData.slice(0, 5).map((item, index) => (
                <div key={index} className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-lead">{item.facility_name} </span>
                    <span className="tb-sub">{item.country}</span>
                  </div>
                  <div className="nk-tb-col tb-col-mb">
                    <ul class="d-flex gx-1">
                      <li class="text-facebook">
                        <em class="icon ni ni-facebook-f"></em>
                      </li>
                      <li class="text-instagram">
                        <em class="icon ni ni-instagram"></em>
                      </li>
                      <li class="text-linkedin">
                        <em class="icon ni ni-linkedin"></em>
                      </li>
                      <li class="text-twitter">
                        <em class="icon ni ni-twitter"></em>
                      </li>
                      <li class="text-youtube">
                        <em class="icon ni ni-youtube-fill"></em>
                      </li>
                    </ul>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <Badge
                      className="badge-dim"
                      color={
                        item.status === "Approved" ? "success" : item.status === "Pending Review" ? "warning" : "danger"
                      }
                    >
                      {item.status}
                    </Badge>
                  </div>
                  <div className="nk-tb-col tb-col-md">
                    <div className="user-avatar-group">
                      <div class="user-avatar xs">
                        <img src="./images/avatar/e-sm.jpg" alt="" />
                      </div>
                      <div class="user-avatar xs">
                        <img src="./images/avatar/f-sm.jpg" alt="" />
                      </div>
                      <div class="user-avatar xs">
                        <img src="./images/avatar/g-sm.jpg" alt="" />
                      </div>
                      <div class="user-avatar xs">
                        <span>2+</span>
                      </div>
                    </div>
                  </div>
                 {/*  <div className="nk-tb-col text-end">
                    <Button className="btn-dim " size="sm" color="success" onClick={() => editFacility(item.name)}>
                      Edit
                    </Button>
                  </div> */}
                </div>
              ))}
          </div>
        </div>
      </Card>

      <EditFacility modal={modal} facilityData={facilityEdit} closeModal={closeModal}/>
    </>
  );
};

export default ActiveCampaign;
