export const category = [
  {
    name: "*",
    text: "All",
  },
  {
    name: "blog-content",
    text: "Blog Content",
  },
  {
    name: "social-media",
    text: "Social Media",
  },
  {
    name: "website-copy-seo",
    text: "Website Copy & SEO",
  },
];

export const templates = [
  {
    id: "tid01",
    title: "Blog Ideas",
    excerpt: "Produce trendy blog ideas for your business that engages.",
    icon: "bulb-fill",
    theme: "primary",
    category: "blog-content",
    popular: true,
  },
  {
    id: "tid02",
    title: "Blog Post Outline",
    excerpt: "Create lists and outlines for your articles that performs best.",
    icon: "list-thumb-fill",
    theme: "primary",
    category: "blog-content",
  },
  {
    id: "tid03",
    title: "Blog Post Writer",
    excerpt: "Write blog articles in minutes with the most advanced AI writing pilot.",
    icon: "pen-fill",
    theme: "primary",
    category: "blog-content",
  },
  {
    id: "tid04",
    title: "Blog Paragraph Writer",
    excerpt: "Write human centric paragraph based on your blog subheading.",
    icon: "file-text-fill",
    theme: "primary",
    category: "blog-content",
    badge: "Popular",
  },
  {
    id: "tid05",
    title: "Pros & Cons",
    excerpt: "Write the pros and cons of a product, service or website for your blog article.",
    icon: "clipboad-check-fill",
    theme: "primary",
    category: "blog-content",
  },
  {
    id: "tid06",
    title: "Blog Conclusions",
    excerpt: "End your blog articles with an engaging conclusion paragraph.",
    icon: "flag-fill",
    theme: "primary",
    category: "blog-content",
  },
  {
    id: "tid07",
    title: "Social Media Posts",
    excerpt: "Creative and engaging social media post for your brand.",
    icon: "spark-fill",
    theme: "blue",
    category: "social-media",
    badge: "New",
    popular: true,
  },
  {
    id: "tid08",
    title: "Facebook Headlines",
    excerpt: "Write catchy and convincing headlines to make your Facebook Ads stand out.",
    icon: "facebook-circle",
    theme: "blue",
    category: "social-media",
  },
  {
    id: "tid09",
    title: "Google Ads Description",
    excerpt: "Write a Google Ads description that stands out and generates leads.",
    icon: "google",
    theme: "dark",
    category: "social-media",
    badge: "Popular",
  },
  {
    id: "tid10",
    title: "YouTube Tags Generator",
    excerpt: "Generate SEO optimized tags/keywords for your YouTube video.",
    icon: "youtube-fill",
    theme: "danger",
    category: "social-media",
    popular: true,
  },
  {
    id: "tid11",
    title: "LinkedIn Posts",
    excerpt: "Create content for your LinkedIn profile that will help you to grow.",
    icon: "linkedin-round",
    theme: "indigo",
    category: "social-media",
  },
  {
    id: "tid12",
    title: "Twitter Generator",
    excerpt: "Get ideas for tweets that will engage your followers.",
    icon: "twitter",
    theme: "azure",
    category: "social-media",
  },
  {
    id: "tid13",
    title: "Website Headlines/Copy",
    excerpt: "Generate professional copy for your website that converts users.",
    icon: "laptop",
    theme: "purple",
    category: "website-copy-seo",
    popular: true,
  },
  {
    id: "tid14",
    title: "FAQs Generator",
    excerpt: "Generate frequently asked questions based on your product description.",
    icon: "help-fill",
    theme: "purple",
    category: "website-copy-seo",
  },
  {
    id: "tid15",
    title: "About Us",
    excerpt: "Generate content that is unique to represent about your business.",
    icon: "building-fill",
    theme: "purple",
    category: "website-copy-seo",
  },
  {
    id: "tid16",
    title: "Testimonials/Reviews",
    excerpt: "Add authentic social proof to your website by generating user testimonials.",
    icon: "quote-right",
    theme: "purple",
    category: "website-copy-seo",
  },
];
