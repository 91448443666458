import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { Label, Input } from "reactstrap";
import hospitalPNG from "../images/dropdown-icons/hospital.png";
import clinicPNG from "../images/dropdown-icons/clinic.png";
import medlabPNG from "../images/dropdown-icons/laboratory.png";
import companyPNG from "../images/dropdown-icons/office-building.png";
import pharmacyPNG from "../images/dropdown-icons/pharmacy.png";
import daycarePNG from "../images/dropdown-icons/day-care.png";
import customerPNG from "../images/dropdown-icons/customer-service.png";
import hospitalpharmacyPNG from "../images/dropdown-icons/hospital-pharmacy.png";
import opticalPNG from "../images/dropdown-icons/optical-shop.png";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PreviewCard,
  Button,
} from "../components/Component";
import axios from "axios";
import Success from "../components/popup/Success";

const AddFacility = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [socialMediaInputs, setSocialMediaInputs] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState([]);
  const [insuranceValue, setInsuranceValue] = useState("");
  const [socialMediaValue, setSocialMediaValue] = useState("");
  const [socialMediaLink, setSocialMediaLink] = useState("");
  const socialMediaOptions = ["Google", "Facebook", "YouTube", "Instagram"];

  const handleChange = async (e) => {
    const token = localStorage.getItem("userToken");
    const files = Array.from(e.target.files);

    if (files.length > 3) {
      alert("You can only upload up to 3 images");
      return;
    }

    setSelectedImages(files);
    setLoading(true);
    let imageUrls = [];
    for (const file of files) {
      const imageUrl = URL.createObjectURL(file);
      const data = new FormData();
      data.append("file", file);
      data.append("imageUrl", imageUrl);

      try {
        const response = await axios.post(encodeURI("https://api.hospitalsinuae.com/api/method/upload_file"), data, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        });
        imageUrls.push(response.data.message.file_url);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    setUploadedImageUrls(imageUrls);
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      facility_type: "",
      facility_name: "",
      emirate: "",
      location: "",
      area: "",
      website: "",
      email: "",
      contact: "",
      whatsapp: "",
      po_no: "",
      status: "",
      google_map_link: "",
      image_1: "",
      image_2: "",
      image_3: "",
    },
    validationSchema: Yup.object({
      facility_type: Yup.string().required("Facility Type is required"),
      facility_name: Yup.string().required("Facility Name is required"),
    }),
    onSubmit: (values) => {
      const USR_TOKEN = localStorage.getItem("userToken");

      const payload = {
        ...values,
        image_1: uploadedImageUrls[0] || "",
        image_2: uploadedImageUrls[1] || "",
        image_3: uploadedImageUrls[2] || "",
        social: socialMediaInputs.map((input) => ({
          social_media: input.type,
          link: input.value,
        })),
        insurance: selectedInsurance.map((insurance) => ({
          insurance: insurance.name,
          insurance_name: insurance.insurance_company,
          insurance_image: insurance.logo,
        })),
      };

      axios
        .post("https://api.hospitalsinuae.com/api/resource/Facility", payload, {
          headers: {
            Authorization: USR_TOKEN,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setAlertSuccess(true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const getInsuranceList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");

    axios
      .get(`https://api.hospitalsinuae.com/api/resource/Insurance?fields=["name","insurance_company","logo"]`, {
        headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
      })
      .then((response) => {
        setInsuranceData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getInsuranceList();
  }, []);

  const getIcon = () => {
    switch (formik.values.facility_type) {
      case "Hospital":
        return <img src={hospitalPNG} width={60} height={60} />;
      case "Clinic":
        return <img src={clinicPNG} width={60} height={60} />;
      case "Medical Lab":
        return <img src={medlabPNG} width={60} height={60} />;
      case "Company":
        return <img src={companyPNG} width={60} height={60} />;
      case "Pharmacy":
        return <img src={pharmacyPNG} width={60} height={60} />;
      case "Day Care":
        return <img src={daycarePNG} width={60} height={60} />;
      case "Customer":
        return <img src={customerPNG} width={60} height={60} />;
      case "Hospital Pharmacy":
        return <img src={hospitalpharmacyPNG} width={60} height={60} />;
      case "Optical":
        return <img src={opticalPNG} width={60} height={60} />;
      default:
        return null;
    }
  };
  const addSocialMediaInput = () => {
    if (socialMediaValue && socialMediaLink && !socialMediaInputs.find((input) => input.type === socialMediaValue)) {
      setSocialMediaInputs([...socialMediaInputs, { type: socialMediaValue, value: socialMediaLink }]);
      setSocialMediaValue("");
      setSocialMediaLink("");
    }
  };

  const removeSocialMediaInput = (index) => {
    const newInputs = socialMediaInputs.filter((_, i) => i !== index);
    setSocialMediaInputs(newInputs);
  };

  const availableSocialMediaOptions = socialMediaOptions.filter(
    (option) => !socialMediaInputs.find((input) => input.type === option)
  );

  const addInsurance = () => {
    const selected = insuranceData.find((item) => item.name === insuranceValue);
    if (selected && !selectedInsurance.find((item) => item.name === selected.name)) {
      setSelectedInsurance([...selectedInsurance, selected]);
      setInsuranceValue("");
    }
  };

  const removeInsurance = (index) => {
    const newInsurance = selectedInsurance.filter((_, i) => i !== index);
    setSelectedInsurance(newInsurance);
  };

  const availableInsuranceOptions = insuranceData.filter(
    (insurance) => !selectedInsurance.find((item) => item.name === insurance.name)
  );

  return (
    <React.Fragment>
      <Head title="Add Facility"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add Facility
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <PreviewCard>
            <form onSubmit={formik.handleSubmit}>
              <div className="row mt-4">
                <Col md="4">
                  <div className="form-group">
                    <Label htmlFor="facility_type" className="form-label">
                      Facility Type <span class="text-danger">*</span>
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="facility_type"
                        id="facility_type"
                        value={formik.values.facility_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Facility Type</option>
                        <option value="Hospital">Hospital</option>
                        <option value="Clinic">Clinic</option>
                        <option value="Medical Lab">Medical Lab</option>
                        <option value="Company">Company</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Day Care">Day Care</option>
                        <option value="Customer">Customer</option>
                        <option value="Hospital Pharmacy">Hospital Pharmacy</option>
                        <option value="Optical">Optical</option>
                      </Input>
                      {formik.touched.facility_type && formik.errors.facility_type ? (
                        <div className="text-danger">{formik.errors.facility_type}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mt-3">
                  <h2>{getIcon()}</h2>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="facility_name" className="form-label">
                      Facility Name <span class="text-danger">*</span>
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="facility_name"
                        name="facility_name"
                        value={formik.values.facility_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Facility Name"
                      />
                      {formik.touched.facility_name && formik.errors.facility_name ? (
                        <div className="text-danger">{formik.errors.facility_name}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="emirate" className="form-label">
                      Emirate
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="emirate"
                        id="emirate"
                        value={formik.values.emirate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Emirate</option>
                        <option value="Abu Dhabi">Abu Dhabi</option>
                        <option value="Ajman">Ajman</option>
                        <option value="Al Ain">Al Ain</option>
                        <option value="Dubai">Dubai</option>
                        <option value="Fujairah">Fujairah</option>
                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                        <option value="Sharjah">Sharjah</option>
                        <option value="Umm Al Quwain">Umm Al Quwain</option>
                      </Input>
                      {formik.touched.emirate && formik.errors.emirate ? (
                        <div className="text-danger">{formik.errors.emirate}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="location" className="form-label">
                      Address
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="location"
                        name="location"
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="area" className="form-label">
                      Area
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="area"
                        name="area"
                        value={formik.values.area}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Area"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="website" className="form-label">
                      Web
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="website"
                        name="website"
                        value={formik.values.website}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Web"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="email" className="form-label">
                      Email
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="contact" className="form-label">
                      Tel
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="contact"
                        name="contact"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Tel"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="whatsapp" className="form-label">
                      WhatsApp
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="whatsapp"
                        name="whatsapp"
                        value={formik.values.whatsapp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Whatsapp"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="po_no" className="form-label">
                      Po box
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="po_no"
                        name="po_no"
                        value={formik.values.po_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Po box"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="8" className="mt-4">
                  <div className="form-group">
                    <label className="form-label">Upload Images (Please upload 3 images )</label>
                    <div className="form-control-wrap">
                      <div className="form-file">
                        <Input type="file" multiple id="customMultipleFiles" onChange={handleChange} />
                      </div>
                      {loading ? (
                        <h6 class="title text-center mt-2">Loading.....</h6>
                      ) : (
                        <>
                          {selectedImages.length > 0 && (
                            <div className="mt-2">
                              {selectedImages.map((file, index) => (
                                <img
                                  key={index}
                                  src={URL.createObjectURL(file)}
                                  alt={`Selected ${index + 1}`}
                                  className="m-3"
                                  style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                />
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="status" className="form-label">
                      Status
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="Pending Review">Pending Review</option>
                        <option value="Cancelled">Cancelled</option>
                      </Input>
                    </div>
                  </div>
                </Col>
                <div className="row d-flex col-md-12">
                <div className="pt-4 pb-4 mt-5 col-md-7" style={{ backgroundColor: "rgb(198, 195, 190)"}}>
                  <Col md="12">
                    <Label htmlFor="social_media" className="form-label mr-3">
                      Social Media
                    </Label>
                    <div className="form-group d-flex align-items-center">
                      <Input
                        type="select"
                        name="social_media"
                        id="social_media"
                        value={socialMediaValue}
                        onChange={(e) => setSocialMediaValue(e.target.value)}
                        className="mr-3"
                        style={{ flexBasis: "45%" }}
                      >
                        <option value="">Select Social Media</option>
                        {availableSocialMediaOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Input>
                      <Input
                        type="text"
                        placeholder="Enter Social Media Link"
                        value={socialMediaLink}
                        onChange={(e) => setSocialMediaLink(e.target.value)}
                        className="mr-3"
                        style={{ flexBasis: "60%", marginLeft: "10px" }}
                      />
                      <Button
                        type="button"
                        color="primary"
                        style={{ marginLeft: "10px" }}
                        onClick={addSocialMediaInput}
                      >
                        <Icon name="plus-sm"></Icon>
                      </Button>
                    </div>
                    {socialMediaInputs.map((input, index) => (
                      <div className="form-group d-flex align-items-center" key={index}>
                        <h6 className="mr-3 mb-0">
                          {`${input.type} Link : `}
                          <a href={input.value} target="_blank" rel="noopener noreferrer">
                            &nbsp;{input.value}
                          </a>
                        </h6>
                        {/* <Button
                        color="danger"
                        size="sm"
                        className="rounded-circle"
                        onClick={() => removeSocialMediaInput(index)}
                      > */}
                        &nbsp; <Icon name="cross-sm" onClick={() => removeSocialMediaInput(index)}></Icon>
                        {/* </Button> */}
                      </div>
                    ))}
                  </Col>
                </div>
                <div className="col-md-5 pt-4 pb-4 mt-5" style={{ backgroundColor: "rgb(211, 218, 255)"}}>
                  <Col md="12">
                    <Label htmlFor="insurance" className="form-label mr-3">
                      Insurance
                    </Label>
                    <div className="form-group d-flex align-items-center">
                      <Input
                        type="select"
                        name="insurance"
                        id="insurance"
                        value={insuranceValue}
                        onChange={(e) => setInsuranceValue(e.target.value)}
                        className="mr-3"
                        style={{ flexBasis: "80%" }}
                      >
                        <option value="">Select Insurance</option>
                        {availableInsuranceOptions.map((insurance) => (
                          <option key={insurance.name} value={insurance.name}>
                            {insurance.insurance_company}
                          </option>
                        ))}
                      </Input>
                      <Button type="button" color="primary" style={{ marginLeft: "10px" }} onClick={addInsurance}>
                        <Icon name="plus-sm"></Icon>
                      </Button>
                    </div>
                    {selectedInsurance.map((insurance, index) => (
                      <div className="form-group d-flex align-items-center" key={index}>
                        <img
                          src={`https://api.hospitalsinuae.com${insurance.logo}`}
                          alt={insurance.insurance_company}
                          style={{ width: "50px", height: "50px", marginRight: "10px", borderRadius: "30px" }}
                        />
                        <h6 className="mr-3 mb-0">{insurance.insurance_company}</h6>
                        &nbsp;<Icon name="cross-sm" onClick={() => removeInsurance(index)}></Icon>
                      </div>
                    ))}
                  </Col>
                </div>
                </div>
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="google_map_link" className="form-label">
                      Google Map Link
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="google_map_link"
                        name="google_map_link"
                        value={formik.values.google_map_link}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Address"
                      />
                    </div>
                    {formik.values.google_map_link && (
                      <div className="mt-4">
                        <iframe
                          src={formik.values.google_map_link}
                          width="900"
                          height="350"
                          frameborder="0"
                          style={{ border: 0 }}
                          allowfullscreen=""
                          aria-hidden="false"
                          tabindex="0"
                        ></iframe>
                      </div>
                    )}
                  </div>
                </Col>

                <Col md="12" className="mt-5 d-flex justify-content-end">
                  <Button type="submit" color="primary">
                    Add Facility
                  </Button>
                </Col>
              </div>
            </form>
          </PreviewCard>
        </Block>
      </Content>
      <Success show={alertSuccess} url={"/facilities"} />
    </React.Fragment>
  );
};

export default AddFacility;
