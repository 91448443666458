import React, { useContext, useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
} from "../components/Component";
import { filterRole, filterStatus, userData } from "../pages/pre-built/user-manage/UserData";
import { bulkActionOptions, findUpper } from "../utils/Utils";
import { Link } from "react-router-dom";
import EditModal from "../pages/pre-built/user-manage/EditModal";
import AddModal from "../pages/pre-built/user-manage/AddModal";
import axios from "axios";
import EditFacility from "../components/popup/EditFacility";
import { getCurrentUser } from "../services/auth";

const Facilities = () => {
  const [sm, updateSm] = useState(false);
  const [facilityEdit, SetFacilityEdit] = useState("");
  const [hospitalData, setHospitalData] = useState("");
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const editFacility = (id) => {
    SetFacilityEdit(id);
    setModal(true);
  };
  
  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      localStorage.setItem("user", JSON.stringify(currentUser));
    } else {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, []);

  const getHospitalList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    setLoading(true);
    if (user) {
      const USR_MAIL = user.uid;
      axios
        .get(
          `https://api.hospitalsinuae.com/api/resource/Facility?fields=["name","facility_name","country","location","facility_type","emirate","status"]&filters=[["owner","=","${USR_MAIL}"]]`,
          {
            headers: { Authorization: USR_TOKEN, "content-type": "application/json" },
          }
        )
        .then((response) => {
          setHospitalData(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      getHospitalList();
    }
  }, [user]);

  /*  const [modal, setModal] = useState({
    edit: false,
    add: false,
  }); */

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);

  /*  const closeModal = () => {
    setModal({ add: false });
  
  };

  const closeEditModal = () => {
    setModal({ edit: false });
   
  }; */

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const hospitals = hospitalData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Facilities"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Facilities
              </BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>You have total 2,595 users.</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Link to={`${process.env.PUBLIC_URL}/add-facility`}>
                        <Button color="primary" className="btn-icon p-1">
                          <Icon name="plus"></Icon>
                          Add Facility
                        </Button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
        {loading ? (<h6 class="title text-center">Loading.....</h6>) :
          (<DataTable className="card-stretch">
            <div class="card-title-group p-4">
              <div class="card-title">
                <h6 class="title">Active Facilities</h6>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Facility</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text">Type</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Departments</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Doctors</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Plan</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Plan Expiry</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Channels</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">User Assigned</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Actions</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {hospitals.length > 0
                ? hospitals.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                          <Link to={`${process.env.PUBLIC_URL}/facility-details/${item.name}`}>
                            <div className="user-card">
                              <div className="user-info">
                                <span className="tb-lead">{item.facility_name} </span>
                                <span>{item.country}</span>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className=" badge rounded-pill bg-primary">{item.facility_type}</span>
                        </DataTableRow>
                        <DataTableRow size="mb text-center">
                          <span className="">5</span>
                        </DataTableRow>
                        <DataTableRow size="mb text-center">
                          <span className="">7</span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="badge bg-outline-primary badge badge-dim bg-success">Premium</span>
                        </DataTableRow>
                        <DataTableRow size="mb text-center">
                          <span className="">10</span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <ul class="d-flex gx-1">
                            <li class="text-facebook">
                              <em class="icon ni ni-facebook-f"></em>
                            </li>
                            <li class="text-instagram">
                              <em class="icon ni ni-instagram"></em>
                            </li>
                            <li class="text-linkedin">
                              <em class="icon ni ni-linkedin"></em>
                            </li>
                            <li class="text-twitter">
                              <em class="icon ni ni-twitter"></em>
                            </li>
                            <li class="text-youtube">
                              <em class="icon ni ni-youtube-fill"></em>
                            </li>
                          </ul>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span
                            className={`badge badge-dim bg-${
                              item.status === "Approved"
                                ? "success"
                                : item.status === "Pending Review"
                                ? "warning"
                                : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <div class="user-avatar-group">
                            <div class="user-avatar xs">
                              <img src="./images/avatar/e-sm.jpg" alt="" />
                            </div>
                            <div class="user-avatar xs">
                              <img src="./images/avatar/f-sm.jpg" alt="" />
                            </div>
                            <div class="user-avatar xs">
                              <img src="./images/avatar/g-sm.jpg" alt="" />
                            </div>
                            <div class="user-avatar xs">
                              <span>2+</span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {/* <li className="nk-tb-action-hidden">
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li> */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => editFacility(item.name)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {hospitals.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={hospitalData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>)}
        </Block>

        <EditFacility modal={modal} facilityData={facilityEdit} closeModal={closeModal} fetchData={getHospitalList} />
      </Content>
    </React.Fragment>
  );
};
export default Facilities;
