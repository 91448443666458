import React, { useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewCard,
  PreviewAltCard,
} from "../components/Component";
import { Label, Input } from "reactstrap";

const AddJob = () => {
  const [rangeStart, setRangeStart] = useState(null);
  const [expectJoinDate, setExpectJoinDate] = useState(null);
  const [jobStartDate, setJobStartDate] = useState(null);
  const [rangeEnd, setRangeEnd] = useState();
  return (
    <React.Fragment>
      <Head title="Add Job Vacancy" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Add Job Vacancy
              </BlockTitle>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <PreviewCard>
            <div className="row">
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Occupation <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Occupation</option>
                      <option value="">Occupational Therapist</option>
                      <option value="">Dietitian</option>
                      <option value="">Nursing Assistant</option>
                      <option value="">Emergency Medical Technician</option>
                      <option value="">Medical Biller</option>
                      <option value="">Phlebotomist</option>
                      <option value="">Surgeon</option>
                      <option value="">Medical Social Worker</option>
                      <option value="">Registered Nurse</option>
                      <option value="">Medical Assistant</option>
                      <option value="">Physical Therapist</option>
                      <option value="">Audiologist</option>
                      <option value="">Medical Secretary</option>
                      <option value="">Physician</option>
                      <option value="">Healthcare Manager</option>
                      <option value="">Optometrist</option>
                      <option value="">Pharmacist</option>
                      <option value="">Dentist</option>
                      <option value="">Medical Technologist</option>
                      <option value="">Radiographer</option>
                      <option value="">Nurse</option>
                      <option value="">Respiratory Therapist</option>
                      <option value="">Medical Records Clerk</option>
                      <option value="">Physician assistant</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Job Programme <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Job Programme</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="first_name" className="form-label">
                    Job Title In English <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="first_name"
                      name="first_name"
                      /*  value=""
                      onChange={(e) => {}} */
                      placeholder="Enter Job Title in english"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="first_name" className="form-label">
                    Job Title In Arabic <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="first_name"
                      name="first_name"
                      /* value=""
                      onChange={(e) => {}} */
                      placeholder="Enter Job Title in arabic"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Job Type <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Job Type</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Job Category <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Job Category</option>
                      <option value="">Registered Nurse</option>
                      <option value="">Receptionist</option>
                      <option value="">Pharmacy Technician</option>
                      <option value="">Nursing Assistant</option>
                      <option value="">Dietitian</option>
                      <option value="">Physician Assistant</option>
                      <option value="">Physical Therapist</option>
                      <option value="">Licensed Practical Nurse</option>
                      <option value="">Medical Secretary</option>
                      <option value="">Emergency Medical Technician</option>
                      <option value="">Health Administrator</option>
                      <option value="">Radiographer</option>
                      <option value="">Human Resources Manager</option>
                      <option value="">Dental Assistant</option>
                      <option value="">Medical Residents</option>
                      <option value="">Marketing Executive</option>
                      <option value="">Occupational Therapist</option>
                      <option value="">Lab Technician</option>
                      <option value="">Health Administration</option>
                      <option value="">Surgeon</option>
                      <option value="">Medical Assistant</option>
                      <option value="">Medical Transcriptionists</option>
                      <option value="">Phlebotomist</option>
                      <option value="">Medical Biller</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Work From <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Work From</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Emirates <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Emirates</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    City <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select City</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label>
                    Job offer Publish Date <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap d-flex">
                    <div className="input-daterange date-picker-range input-group d-flex">
                      <DatePicker
                        selected={rangeStart}
                        onChange={setRangeStart}
                        selectsStart
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        className="form-control"
                        placeholderText="Start Date"
                      />{" "}
                      <div className="input-group-addon">-</div>
                      <DatePicker
                        selected={rangeEnd}
                        onChange={setRangeEnd}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        selectsEnd
                        minDate={rangeStart}
                        className="form-control"
                        placeholderText="End Date"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="first_name" className="form-label">
                    Link to more information <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="first_name"
                      name="first_name"
                      /* value=""
                      onChange={(e) => {}} */
                      placeholder="Enter Link to more information"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label>
                    Expected joining Date <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <DatePicker
                      selected={expectJoinDate}
                      onChange={setExpectJoinDate}
                      customInput={
                        <div className="custom-date-picker-input">
                          <div className="form-icon form-icon-right">
                            <Icon name="calendar" />
                          </div>
                          <input
                            className="form-control date-picker"
                            type="text"
                            value={expectJoinDate ? expectJoinDate.toLocaleDateString() : ""}
                            placeholder="Enter Expected joining Date"
                            readOnly
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label>
                    Job Starting Date <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <DatePicker
                      selected={jobStartDate}
                      onChange={setJobStartDate}
                      customInput={
                        <div className="custom-date-picker-input">
                          <div className="form-icon form-icon-right">
                            <Icon name="calendar" />
                          </div>
                          <input
                            className="form-control date-picker"
                            type="text"
                            value={jobStartDate ? jobStartDate.toLocaleDateString() : ""}
                            placeholder="Enter Job Starting Date"
                            readOnly
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="first_name" className="form-label">
                    Number of possitions to be filled <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="first_name"
                      name="first_name"
                      /* value=""
                      onChange={(e) => {}} */
                      placeholder="Enter Number of possitions to be filled"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Salary Range <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Salary Range </option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Benefits <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Benefits</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <div className="row">
                <Col md="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Job Description
                    </Label>
                    <ReactQuill
                      /* value={formik.values.industry_relationships}
                      onChange={(content) => formik.setFieldValue("industry_relationships", content)} */
                      placeholder="Job Description"
                    />
                  </div>
                </Col>
                <Col md="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Job Description Arabic
                    </Label>
                    <ReactQuill
                      /* value={formik.values.industry_relationships}
                      onChange={(content) => formik.setFieldValue("industry_relationships", content)} */
                      placeholder="Job Description Arabic"
                    />
                  </div>
                </Col>
                <Col md="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Responsibilities
                    </Label>
                    <ReactQuill
                      /* value={formik.values.industry_relationships}
                      onChange={(content) => formik.setFieldValue("industry_relationships", content)} */
                      placeholder="Responsibilities "
                    />
                  </div>
                </Col>
                <Col md="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Responsibilities Arabic
                    </Label>
                    <ReactQuill
                      /* value={formik.values.industry_relationships}
                      onChange={(content) => formik.setFieldValue("industry_relationships", content)} */
                      placeholder="Responsibilities Arabic "
                    />
                  </div>
                </Col>
              </div>
            </div>
          </PreviewCard>
        </Block>
        <Block>
          <PreviewCard>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Job Seeker
              </BlockTitle>
            </BlockHeadContent>
            <div className="row">
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Minimum Qualifications <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Minimum Qualifications</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="first_name" className="form-label">
                    Minimum required GPA <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="first_name"
                      name="first_name"
                      /* value=""
                      onChange={(e) => {}} */
                      placeholder="Minimum required GPA"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Specialization <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Specialization</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Work Experience <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Work Experience</option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Age <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Age </option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Language <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Language </option>
                      <option value="">Option 1</option>
                      <option value="">Option 2</option>
                      <option value="">Option 3</option>
                      <option value="">Option 4</option>
                      <option value="">Option 5</option>
                      <option value="">Option 6</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="hospital" className="form-label">
                    Gender <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input type="select" name="hospital" id="hospital" onChange={(e) => {}}>
                      <option value="">Select Gender</option>
                      <option value="">Male</option>
                      <option value="">Female</option>
                      <option value="">Others</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="12" className="mt-5 d-flex justify-content-end">
                <Button type="submit" color="primary">
                  Add Job Vacancy
                </Button>
              </Col>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddJob;
