import React, { useState,useEffect } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
} from "../components/Component";
import CampaignInfoCard from "../components/partials/campaign/campaign-info-card/CampaignInfoCard";
import CampaignPerformance from "../components/partials/campaign/campaign-performance/CampaignPerformance";
import {
  runningCampaign,
  totalAudience,
  avgRating,
  newSubscriber,
  performanceOverview,
} from "../components/partials/charts/campaign/CampaignData";
import TopChannels from "../components/partials/campaign/top-channels/TopChannels";
import ActiveCampaign from "../components/partials/campaign/active-campaign/ActiveCampaign";
import KeyStatistics from "../components/partials/campaign/key-statistics/KeyStatistics";
import CampaignOverview from "../components/partials/campaign/campaign-overview/CampaignOverview";
import ClicksbyLocation from "../components/partials/campaign/clicks-by-location/ClicksbyLocation";
import WebConvrate from "../components/partials/campaign/web-convrate/WebConvrate";
import {getCurrentUser} from "../services/auth"
import JobCard from "../components/partials/campaign/custom-job-card/JobCard";
import SocialMediaFeed from "../components/partials/campaign/social-media-feed/SocialMediaFeed";
import CountryList from "../components/partials/campaign/custom-country-card/CountryList";

const CampaignHomePage = () => {
  const [sm, updateSm] = useState(false);
  const [userDetails, setUserDetails] = useState("");

  useEffect(() => {
    setUserDetails(getCurrentUser());
    const USR_TOKEN = localStorage.getItem("userToken");
    if (!USR_TOKEN) {
      window.location.href = `${process.env.PUBLIC_URL}/auth-login`;
    } else {
      setUserDetails(getCurrentUser());
    }
  }, []);
  return (
    <React.Fragment>
      <Head title="Dash Board" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Hospital Management</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Hospital Management Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                {/* <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button className="btn-dim btn-white btn-outline-light">
                        <Icon name="plus"></Icon>
                        <span>
                          <span className="d-md-none">Add</span>
                          <span className="d-none d-md-block">Add Campaign</span>
                        </span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button className="btn-primary">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li>
                  </ul>
                </div> */}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col lg="3" sm="6">
              <CampaignInfoCard
                theme="primary"
                title="Active Hospitals"
                amount="2"
                change="12.4%"
                direction="up"
                /* chart="line" */
                chartData={runningCampaign}
              />
            </Col>
            <Col lg="3" sm="6">
              <CampaignInfoCard
                theme="info"
                title="Total Audience"
                amount="857.6K"
                change="12.4%"
                direction="up"
                /* chart="line" */
                chartData={totalAudience}
              />
            </Col>
            <Col lg="3" sm="6">
              <CampaignInfoCard
                theme="warning"
                title="Avg. Rating"
                amount="4.5"
                change="12.4%"
                direction="up"
                /* chart="bar" */
                chartData={avgRating}
              />
            </Col>
            <Col lg="3" sm="6">
              <CampaignInfoCard
                theme="danger"
                title="Clicks"
                amount="175.2K"
                change="12.4%"
                direction="up"
                /* chart="line" */
                chartData={newSubscriber}
              />
            </Col>
            <Col /* xxl="4" */ md="8">
              <ActiveCampaign />
            </Col>
{/*             <Col xxl="8" lg="7">
              <CampaignPerformance title="Performance Overview (Departments)" chartData={performanceOverview} />
            </Col> */}
            <Col /* xxl="4" */ md="4">
              <TopChannels />
            </Col>

            <Col xxl="4" md="4">
              <KeyStatistics />
            </Col>
            {/* <Col xxl="4" md="6">
              <CampaignOverview />
            </Col> */}
            <Col xxl="4" md="4">
              <ClicksbyLocation />
            </Col>
            <Col xxl="4" md="4">
              <JobCard />
            </Col>
            <Col xxl="8" md="8">
              <SocialMediaFeed />
            </Col>
            <Col xxl="4" md="4">
              <CountryList />
            </Col>
            {/* <Col xxl="4" md="6">
              <WebConvrate />
            </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CampaignHomePage;
