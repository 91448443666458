import React from "react";
import { Card, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../../Component";
import avatar from "../../../../images/empty/default-img.jpg"

const data = [
  { name: "Abu Bin Ishtiyak ", amount: "14 Jan, 2020"},
  { name: "Joe Larson", amount: "14 Jan, 2020"},
  { name: "Jane Montgomery", amount: "14 Jan, 2020"},
  { name: "Alan Butler", amount: "14 Jan, 2020"},
  { name: "Victoria Lynch", amount: "14 Jan, 2020"},
  { name: "Jane Harris", amount: "14 Jan, 2020"},
  { name: "Emma Walker", amount: "14 Jan, 2020"},
  { name: "Frances Burns", amount: "14 Jan, 2020"},
];

const ClicksbyLocation = () => {
  return (
    <Card className="card-full">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">Reviews</h6>
          </div>
          <div className="card-tools me-n1 mt-n1">
            <div className="card-tools">
              <a href="/reviews" className="link">
                View All
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card-inner pt-0">
        <ul className="my-n2">
          {data.map((item, index) => (
            <li
              key={index}
              className={`align-center justify-initial py-1 gx-1 ${
                index < data.length - 1 ? "border-bottom border-0 border-dashed" : ""
              }`}
            >
              <div class="user-avatar sm">
                <img src={avatar}
                  alt=""
                />
              </div>
              <span className="m-1 lead-text">{item.name}</span>
              <div className="sub-text justify-between">{item.amount}</div>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default ClicksbyLocation;
