import React, { useEffect } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import { Link } from "react-router-dom";

const Success = ({ show, url }) => {
  return (
    <Modal isOpen={show} /* toggle={() => closeModal()} */ className="modal-dialog-centered" size="sm">
      <ModalBody>
        {/* <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a> */}
        <div className="p-2">
          <div class="modal-body modal-body-md">
            <h5 class="modal-title text-center">Successfull</h5>
            <Col md="12" className="mt-4 d-flex justify-content-center">
              <Link to={url}>
                <Button color="primary">OK</Button>
              </Link>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default Success;
