import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Icon,
  Col,
  PreviewCard,
  Button,
} from "../components/Component";
import Success from "../components/popup/Success";
import ReactQuill from "react-quill";
import { getCurrentUser } from "../services/auth";
import AddDoctor from "../components/popup/AddDoctor";

const AddDepartment = () => {
  const [facilityData, setFacilityData] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  /* const [loading, setLoading] = useState(false); */
  const [user, setUser] = useState(null);
  /* const [headPhysicianImg, setHeadPhysicianImg] = useState(null); */
  const [categoryList, setCategoryList] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [doctorModal, setDoctorModal] = useState(false);
  const [selectedCategoryLogo, setSelectedCategoryLogo] = useState(null);
  const [selectedDoctorImage, setSelectedDoctorImage] = useState(null);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      localStorage.setItem("user", JSON.stringify(currentUser));
    } else {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      hospital: "",
      hospital_name: "",
      department_name: "",
      department_head: "",
      head_image: "",
      department_details: "",
      department_logo: "",
      status: "",
    },
    validationSchema: Yup.object({
      hospital_name: Yup.string().required("Hospital Name is required"),
      department_details: Yup.string().max(300, "Description cannot exceed 300 characters"),
    }),
    onSubmit: async (values) => {
      const USR_TOKEN = localStorage.getItem("userToken");
      try {
        const response = await axios.post("https://api.hospitalsinuae.com/api/resource/Hospital%20Department", values, {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        });
        console.log(response.data);
        setAlertSuccess(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getFacilityList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    if (user) {
      const USR_MAIL = user.uid;
      axios
        .get(
          `https://api.hospitalsinuae.com/api/resource/Facility?fields=["name","facility_name","facility_type"]&filters=[["owner","=","${USR_MAIL}"]]`,
          {
            headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          setFacilityData(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getDoctorList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    if (user) {
      const USR_MAIL = user.uid;
      axios
        .get(
          `https://api.hospitalsinuae.com/api/resource/Doctors?fields=["first_name","last_name","profile_image"]&filters=[["owner","=","${USR_MAIL}"]]`,
          {
            headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          const doctors = response.data.data.map((doctor) => ({
            fullName: `${doctor.first_name} ${doctor.last_name}`,
            ...doctor,
          }));
          setDoctorsList(doctors);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      getDoctorList();
      getFacilityList();
    }
  }, [user]);

  const AddNewDoctor = () => {
    setDoctorModal(true);
  };

  const closeModal = () => {
    setDoctorModal(false);
  };

  /* const headPhysicianChange = async (e) => {
    const token = localStorage.getItem("userToken");
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const data = new FormData();
      data.append("file", selectedFile);
      setLoading(true);
      try {
        const response = await axios.post("https://api.hospitalsinuae.com/api/method/upload_file", data, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        });

        const Fileinfo = response.data.message.file_url;
        setHeadPhysicianImg(Fileinfo);
        formik.setFieldValue("head_image", Fileinfo);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }; */

  const getDepartmentCategoryList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");

    axios
      .get(
        `https://api.hospitalsinuae.com/api/resource/Department%20Category?fields=["name","department_name","logo"]`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setCategoryList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDepartmentCategoryList();
  }, []);

  const fetchDepartments = (facilityName) => {
    const USR_TOKEN = localStorage.getItem("userToken");
    axios
      .get(
        `https://api.hospitalsinuae.com/api/resource/Hospital%20Department?fields=["department_name","department_logo"]&filters=[["hospital","=","${facilityName}"]]`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setDepartments(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleHospitalChange = (e) => {
    const selectedFacility = facilityData.find((facility) => facility.facility_name === e.target.value);
    if (selectedFacility) {
      formik.setFieldValue("hospital_name", selectedFacility.facility_name);
      formik.setFieldValue("hospital", selectedFacility.name);
      fetchDepartments(selectedFacility.name); // Fetch departments for the selected hospital
    }
  };

  const handleDepartmentChange = (e) => {
    formik.handleChange(e);
    const selectedCategory = categoryList.find((category) => category.name === e.target.value);
    if (selectedCategory) {
      setSelectedCategoryLogo(selectedCategory.logo);
      formik.setFieldValue("department_logo", selectedCategory.logo);
    } else {
      setSelectedCategoryLogo(null);
      formik.setFieldValue("department_logo", "");
    }
  };

  const handleDoctorChange = (e) => {
    formik.handleChange(e);
    const selectedDoctor = doctorsList.find((doctor) => doctor.fullName === e.target.value);
    if (selectedDoctor) {
      setSelectedDoctorImage(selectedDoctor.profile_image);
      formik.setFieldValue("head_image", selectedDoctor.profile_image);
    } else {
      setSelectedDoctorImage(null);
      formik.setFieldValue("head_image", "");
    }
  };

  return (
    <React.Fragment>
      <Head title="Add Department" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add Department
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <form onSubmit={formik.handleSubmit}>
              <div className="row mt-4">
                <Col md="6">
                  <div className="form-group">
                    <Label htmlFor="hospital_name" className="form-label">
                      Hospital
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="hospital_name"
                        id="hospital_name"
                        value={formik.values.hospital_name}
                        onChange={handleHospitalChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Hospital</option>
                        {facilityData.map((facility) => (
                          <option key={facility.facility_name} value={facility.facility_name}>
                            {facility.facility_name}
                          </option>
                        ))}
                      </Input>
                      {formik.touched.hospital_name && formik.errors.hospital_name ? (
                        <div className="text-danger">{formik.errors.hospital_name}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  {departments.length > 0 && (
                    <div className="form-group ">
                      <Label htmlFor="existing_departments" className="form-label">
                        Existing Departments
                      </Label>
                      <div className="border border-grey border-3 p-2 rounded">
                        {departments.map((department) => (
                          <div className="col-md-12 d-flex ">
                            <p className="mt-3" key={department.name}>
                              {department.department_name}
                            </p>
                            <span className="float-right" style={{ marginLeft: "10px" }}>
                              <img
                                className="float-right m-1"
                                alt={department.department_logo}
                                height={50}
                                src={"https://api.hospitalsinuae.com" + department.department_logo}
                              />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_name" className="form-label">
                      Department
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="department_name"
                        id="department_name"
                        value={formik.values.department_name}
                        onChange={handleDepartmentChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Department</option>
                        {categoryList.map((category) => (
                          <option key={category.name} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </Input>
                      {formik.touched.department_name && formik.errors.department_name ? (
                        <div className="text-danger">{formik.errors.department_name}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="2">
                  {selectedCategoryLogo && (
                    <div className="mt-4">
                      <img
                        src={"https://api.hospitalsinuae.com/" + selectedCategoryLogo}
                        alt="Department Logo"
                        style={{ maxWidth: "100px", height: "80px" }}
                      />
                    </div>
                  )}
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Department Head
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="department_head"
                        id="department_head"
                        value={formik.values.department_head}
                        onChange={handleDoctorChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Doctor</option>
                        {doctorsList.map((doctor) => (
                          <option key={doctor.fullName} value={doctor.fullName}>
                            {doctor.fullName}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className="label label-success btn" onClick={() => AddNewDoctor()}>
                      <span className="badge badge-dim bg-success mt-3">
                        <Icon name="plus"></Icon> Add Doctor
                      </span>
                    </div>
                  </div>
                </Col>
                <Col sm="2">
                  {selectedDoctorImage ? (
                    <div className="mt-2">
                      <img
                        src={"https://api.hospitalsinuae.com" + selectedDoctorImage}
                        alt="Doctor Profile"
                        style={{ maxWidth: "100px" }}
                      />
                    </div>
                  ) : !formik.values.department_head ? (
                    ""
                  ) : (
                    <h6 class="title text-center mt-5">No Image Found</h6>
                  )}
                </Col>
                {/* <Col sm="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="head_image" className="form-label">
                      Department Head Photo
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-file">
                        <Input type="file" id="head_image" name="head_image" onChange={headPhysicianChange} />
                      </div>
                    </div>
                    {loading ? (
                      <h6 class="title text-center mt-2">Loading.....</h6>
                    ) : (
                      <>
                        {headPhysicianImg && (
                          <div className="mt-3">
                            <img
                              src={`https://api.hospitalsinuae.com${headPhysicianImg}`}
                              alt="Department Head"
                              width="100"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Col> */}
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Department Description
                    </Label>
                    <ReactQuill
                      value={formik.values.department_details}
                      onChange={(content) => formik.setFieldValue("department_details", content)}
                      placeholder="Enter department details"
                    />
                    {formik.touched.department_details && formik.errors.department_details ? (
                      <div className="text-danger">{formik.errors.department_details}</div>
                    ) : null}
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="status" className="form-label">
                      Status
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Input>
                    </div>
                  </div>
                </Col>
              </div>
              <Col md="12" className="mt-5 d-flex justify-content-end">
                <Button type="submit" color="primary">
                  Add Department
                </Button>
              </Col>
            </form>
            <div className="row mt-4"></div>
          </PreviewCard>
        </Block>
      </Content>
      <Success show={alertSuccess} url={"/departments"} />
      <AddDoctor modal={doctorModal} closeModal={closeModal} doctorlistupdate={getDoctorList} />
    </React.Fragment>
  );
};

export default AddDepartment;
