import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import avatar from "../../../src/images/empty/default-img.jpg";
import axios from "axios";

const DoctorDetails = ({ modal, closeModal, doctor }) => {
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    FetchDocData();
  }, [doctor]);

  const FetchDocData = async () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    setLoading(true);
    try {
      const response = await axios.get(`https://api.hospitalsinuae.com/api/resource/Doctors/${doctor}`, {
        headers: { Authorization: USR_TOKEN, "content-type": "application/json" },
      });
      setDoctorDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log("this is error", error);
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        {loading ? (
          <h6 class="title text-center mt-2">Loading.....</h6>
        ) : (
          <div className="p-2">
            <div class="nk-block mt-2">
              <div class="row g-gs">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-inner-group">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <div class="card-inner">
                            <div class="user-card user-card-s2">
                              <div class="user-avatar lg bg-primary">
                                <img
                                  src={
                                    doctorDetails.profile_image
                                      ? "https://api.hospitalsinuae.com" + doctorDetails.profile_image
                                      : avatar
                                  }
                                  alt=""
                                />
                              </div>
                              <div class="user-info">
                                <div class="badge bg-light rounded-pill ucap">
                                  {doctorDetails.first_name}
                                  {doctorDetails.last_name}
                                </div>
                                {/* <h5>Department of Cardiology</h5>
                                                            <span class="sub-text">Danat Al Emarat Hospital For Women & Children</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-7">
                          <div class="card-inner">
                            <div class="user-card user-card-s2">
                              {/* <div class="user-avatar lg bg-primary">
                                                            <img src="./images/avatar/b-sm.jpg" alt="">
                                                        </div> */}
                              <div class="user-info">
                                {/* <div class="badge bg-light rounded-pill ucap">Dr. med. Neerod Kumar Jha</div>  */}
                                <h5>Department of {doctorDetails.department}</h5>
                                <span>Specialized in: {doctorDetails.specialist}</span>
                                <span class="sub-text badge bg-outline-primary badge badge-dim bg-success mt-1">
                                  {doctorDetails.hospital}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-inner">
                        <h6 class="overline-title mb-2">Biography</h6>
                        <div class="row g-3">
                          <div class="col-sm-12 col-md-4 col-xl-12 text-justify">
                            <p className="text-justify" dangerouslySetInnerHTML={{ __html: doctorDetails.biography }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
export default DoctorDetails;
