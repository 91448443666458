import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Form, Label, Input } from "reactstrap";
import { Icon, Col, Button } from "../../components/Component";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import hospitalPNG from "../../images/dropdown-icons/hospital.png";
import clinicPNG from "../../images/dropdown-icons/clinic.png";
import medlabPNG from "../../images/dropdown-icons/laboratory.png";
import companyPNG from "../../images/dropdown-icons/office-building.png";
import pharmacyPNG from "../../images/dropdown-icons/pharmacy.png";
import daycarePNG from "../../images/dropdown-icons/day-care.png";
import customerPNG from "../../images/dropdown-icons/customer-service.png";
import hospitalpharmacyPNG from "../../images/dropdown-icons/hospital-pharmacy.png";
import opticalPNG from "../../images/dropdown-icons/optical-shop.png";

const EditFacility = ({ modal, closeModal, facilityData, fetchData }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socialMediaInputs, setSocialMediaInputs] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState([]);
  const [insuranceValue, setInsuranceValue] = useState("");
  const [socialMediaValue, setSocialMediaValue] = useState("");
  const [socialMediaLink, setSocialMediaLink] = useState("");
  const socialMediaOptions = ["Google", "Facebook", "YouTube", "Instagram"];

  const handleChange = async (e) => {
    const token = localStorage.getItem("userToken");
    const files = Array.from(e.target.files);

    if (files.length > 3) {
      alert("You can only upload up to 3 images");
      return;
    }

    setSelectedImages(files);
    setLoading(true);

    let imageUrls = [];
    for (const file of files) {
      const imageUrl = URL.createObjectURL(file);
      const data = new FormData();
      data.append("file", file);

      try {
        const response = await axios.post("https://api.hospitalsinuae.com/api/method/upload_file", data, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        });
        imageUrls.push(response.data.message.file_url);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    setUploadedImageUrls((prevUrls) => {
      const updatedUrls = [...prevUrls];
      for (let i = 0; i < imageUrls.length; i++) {
        updatedUrls[i] = imageUrls[i];
      }
      return updatedUrls;
    });
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      facility_type: "",
      facility_name: "",
      emirate: "",
      location: "",
      area: "",
      website: "",
      email: "",
      contact: "",
      whatsapp: "",
      po_no: "",
      status: "",
      image_1: "",
      image_2: "",
      image_3: "",
    },
    validationSchema: Yup.object({
      facility_type: Yup.string().required("Facility Type is required"),
      facility_name: Yup.string().required("Facility Name is required"),
    }),
    onSubmit: (values) => {
      const USR_TOKEN = localStorage.getItem("userToken");

      const payload = {
        ...values,
        image_1: uploadedImageUrls[0] || "",
        image_2: uploadedImageUrls[1] || "",
        image_3: uploadedImageUrls[2] || "",
        social: socialMediaInputs.map((input) => ({
          social_media: input.type,
          link: input.value,
        })),
        insurance: selectedInsurance.map((insurance) => ({
          insurance: insurance.name,
          insurance_name: insurance.insurance_company,
          insurance_image: insurance.logo,
        })),
      };

      axios
        .put(`https://api.hospitalsinuae.com/api/resource/Facility/${facilityData}`, payload, {
          headers: {
            Authorization: USR_TOKEN,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            fetchData();
            closeModal();
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const getInsuranceList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");

    axios
      .get(`https://api.hospitalsinuae.com/api/resource/Insurance?fields=["name","insurance_company","logo"]`, {
        headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
      })
      .then((response) => {
        setInsuranceData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getInsuranceList();
    if (facilityData) {
      const USR_TOKEN = localStorage.getItem("userToken");
      axios
        .get(`https://api.hospitalsinuae.com/api/resource/Facility/${facilityData}`, {
          headers: {
            Authorization: USR_TOKEN,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data.data;
          console.log("Fetched data for facility:", data);
          formik.setValues({
            facility_type: data.facility_type || "",
            facility_name: data.facility_name || "",
            emirate: data.emirate || "",
            location: data.location || "",
            area: data.area || "",
            website: data.website || "",
            email: data.email || "",
            contact: data.contact || "",
            whatsapp: data.whatsapp || "",
            po_no: data.po_no || "",
            status: data.status || "",
            image_1: data.image_1 || "",
            image_2: data.image_2 || "",
            image_3: data.image_3 || "",
          });

          const BASE_URL = "https://api.hospitalsinuae.com";
          const fullUrls = [data.image_1, data.image_2, data.image_3]
            .filter((url) => url)
            .map((url) => (url.startsWith("http") ? url : BASE_URL + url));
          setUploadedImageUrls(fullUrls);
          setSocialMediaInputs(data.social.map((item) => ({ type: item.social_media, value: item.link })) || []);
          setSelectedInsurance(
            data.insurance.map((item) => ({
              name: item.insurance,
              insurance_company: item.insurance_name,
              logo: BASE_URL + item.insurance_image,
            })) || []
          );
          console.log("Processed insurance data:", selectedInsurance);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [facilityData]);

  const addSocialMediaInput = (e) => {
    e.preventDefault();
    if (socialMediaValue && socialMediaLink && !socialMediaInputs.find((input) => input.type === socialMediaValue)) {
      setSocialMediaInputs([...socialMediaInputs, { type: socialMediaValue, value: socialMediaLink }]);
      setSocialMediaValue("");
      setSocialMediaLink("");
    }
  };

  const removeSocialMediaInput = (index) => {
    const newInputs = socialMediaInputs.filter((_, i) => i !== index);
    setSocialMediaInputs(newInputs);
  };

  const availableSocialMediaOptions = socialMediaOptions.filter(
    (option) => !socialMediaInputs.find((input) => input.type === option)
  );

  const addInsurance = (e) => {
    e.preventDefault();
    const selected = insuranceData.find((item) => item.name === insuranceValue);
    if (selected && !selectedInsurance.find((item) => item.name === selected.name)) {
      const BASE_URL = "https://api.hospitalsinuae.com";
      setSelectedInsurance((prevInsurance) => [
        ...prevInsurance,
        {
          name: selected.name,
          insurance_company: selected.insurance_company,
          logo: BASE_URL + selected.logo,
        },
      ]);
      setInsuranceValue("");
    }
  };

  const removeInsurance = (index) => {
    const newInsurance = selectedInsurance.filter((_, i) => i !== index);
    setSelectedInsurance(newInsurance);
  };

  const availableInsuranceOptions = insuranceData.filter(
    (insurance) => !selectedInsurance.find((item) => item.name === insurance.name)
  );

  const getIcon = () => {
    switch (formik.values.facility_type) {
      case "Hospital":
        return <img src={hospitalPNG} width={60} height={60} alt="Hospital" />;
      case "Clinic":
        return <img src={clinicPNG} width={60} height={60} alt="Clinic" />;
      case "Medical Lab":
        return <img src={medlabPNG} width={60} height={60} alt="Medical Lab" />;
      case "Company":
        return <img src={companyPNG} width={60} height={60} alt="Company" />;
      case "Pharmacy":
        return <img src={pharmacyPNG} width={60} height={60} alt="Pharmacy" />;
      case "Day Care":
        return <img src={daycarePNG} width={60} height={60} alt="Day Care" />;
      case "Customer":
        return <img src={customerPNG} width={60} height={60} alt="Customer" />;
      case "Hospital Pharmacy":
        return <img src={hospitalpharmacyPNG} width={60} height={60} alt="Hospital Pharmacy" />;
      case "Optical":
        return <img src={opticalPNG} width={60} height={60} alt="Optical" />;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="xl">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Edit Facility</h5>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row mt-4">
              <div className="row mt-4">
                <Col md="6">
                  <div className="form-group">
                    <Label htmlFor="facility_type" className="form-label">
                      Facility Type <span className="text-danger">*</span>
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="facility_type"
                        id="facility_type"
                        value={formik.values.facility_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Facility Type</option>
                        <option value="Hospital">Hospital</option>
                        <option value="Clinic">Clinic</option>
                        <option value="Medical Lab">Medical Lab</option>
                        <option value="Company">Company</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Day Care">Day Care</option>
                        <option value="Customer">Customer</option>
                        <option value="Hospital Pharmacy">Hospital Pharmacy</option>
                        <option value="Optical">Optical</option>
                      </Input>
                      {formik.touched.facility_type && formik.errors.facility_type ? (
                        <div className="text-danger">{formik.errors.facility_type}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mt-3">
                  <h2>{getIcon()}</h2>
                </Col>
              </div>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="facility_name" className="form-label">
                    Facility Name <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="facility_name"
                      name="facility_name"
                      value={formik.values.facility_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Facility Name"
                    />
                    {formik.touched.facility_name && formik.errors.facility_name ? (
                      <div className="text-danger">{formik.errors.facility_name}</div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="emirate" className="form-label">
                    Emirate
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="select"
                      name="emirate"
                      id="emirate"
                      value={formik.values.emirate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Emirate</option>
                      <option value="Abu Dhabi">Abu Dhabi</option>
                      <option value="Dubai">Dubai</option>
                      <option value="Sharjah">Sharjah</option>
                      <option value="Ajman">Ajman</option>
                      <option value="Umm Al Quwain">Umm Al Quwain</option>
                      <option value="Fujairah">Fujairah</option>
                      <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="location" className="form-label">
                    Address
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="location"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Address"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="area" className="form-label">
                    Area
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="area"
                      name="area"
                      value={formik.values.area}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Area"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="website" className="form-label">
                    Website
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="website"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Website"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="email" className="form-label">
                    Email
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="contact" className="form-label">
                    Contact
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="contact"
                      name="contact"
                      value={formik.values.contact}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Contact"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="whatsapp" className="form-label">
                    WhatsApp
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="whatsapp"
                      name="whatsapp"
                      value={formik.values.whatsapp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter WhatsApp"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="po_no" className="form-label">
                    PO Box No
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="po_no"
                      name="po_no"
                      value={formik.values.po_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter PO Box No"
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="status" className="form-label">
                    Status
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Status</option>
                      <option value="Approved">Approved</option>
                      <option value="Pending Review">Pending Review</option>
                      <option value="Cancelled">Cancelled</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="8" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="images" className="form-label">
                    Upload Images (Please upload 3 images )
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      className="form-control"
                      type="file"
                      id="images"
                      name="images"
                      accept="image/*"
                      multiple
                      onChange={handleChange}
                    />
                  </div>
                  {loading ? (
                    <h6 class="title text-center mt-2">Loading.....</h6>
                  ) : selectedImages.length > 0 ? (
                    <div className="mt-3">
                      <h6>Selected Images:</h6>
                      <div className="d-flex">
                        {selectedImages.map((image, index) => (
                          <img
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt={`Selected ${index}`}
                            width="100"
                            className="m-3"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "default-image-url.jpg";
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ) : uploadedImageUrls.length > 0 ? (
                    <div className="mt-3">
                      <h6>Existing Images:</h6>
                      <div className="d-flex">
                        {uploadedImageUrls.map((url, index) => (
                          <img
                            key={index}
                            src={url}
                            alt={`Uploaded ${index}`}
                            width="100"
                            className="m-3"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "default-image-url.jpg";
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3">No images selected or uploaded</div>
                  )}
                </div>
              </Col>
              <div className="col-md-12 pt-4 pb-4 mt-5" style={{ backgroundColor: "#e1ddd6" }}>
                <Col md="12" className="mt-4">
                  <Label className="form-label">Social Media</Label>
                  <div className="form-group d-flex align-items-center">
                    <Input
                      type="select"
                      name="social_media"
                      value={socialMediaValue}
                      onChange={(e) => setSocialMediaValue(e.target.value)}
                      className="mr-3"
                      style={{ flexBasis: "30%" }}
                    >
                      <option value="">Select Social Media</option>
                      {availableSocialMediaOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                    <Input
                      type="text"
                      name="social_media_link"
                      placeholder="Link"
                      value={socialMediaLink}
                      onChange={(e) => setSocialMediaLink(e.target.value)}
                      className="mr-2"
                      style={{ flexBasis: "70%", marginLeft: "10px" }}
                    />
                    <Button color="primary" style={{ marginLeft: "10px" }} onClick={(e) => addSocialMediaInput(e)}>
                      <Icon name="plus-sm"></Icon>
                    </Button>
                  </div>
                  <div className="mt-2">
                    {socialMediaInputs.map((input, index) => (
                      <div key={index} className="form-group d-flex align-items-center">
                        {/* <span className="mr-2">
                          {input.type}: {input.value}
                        </span> */}
                        <h6 className="mr-3 mb-0">
                          {`${input.type} Link : `}
                          <a href={input.value} target="_blank" rel="noopener noreferrer">
                            &nbsp;{input.value}
                          </a>
                        </h6>
                        {/*  <Button color="danger" size="sm" onClick={() => removeSocialMediaInput(index)}>
                        Remove
                      </Button> */}
                        <Icon name="cross-sm" onClick={() => removeSocialMediaInput(index)}></Icon>
                      </div>
                    ))}
                  </div>
                </Col>
              </div>
              <div className="col-md-6 pt-4 pb-4 mt-5" style={{ backgroundColor: "rgb(211, 218, 255)" }}>
                <Col md="12" className="mt-4">
                  <Label className="form-label">Insurance</Label>
                  <div className="form-group d-flex align-items-center">
                    <Input
                      type="select"
                      name="insurance"
                      value={insuranceValue}
                      onChange={(e) => setInsuranceValue(e.target.value)}
                      className="mr-2"
                      style={{ flexBasis: "80%" }}
                    >
                      <option value="">Select Insurance</option>
                      {availableInsuranceOptions.map((insurance, index) => (
                        <option key={index} value={insurance.name}>
                          {insurance.insurance_company}
                        </option>
                      ))}
                    </Input>
                    <Button color="primary" style={{ marginLeft: "10px" }} onClick={(e) => addInsurance(e)}>
                      <Icon name="plus-sm"></Icon>
                    </Button>
                  </div>
                  <div className="mt-2">
                    {selectedInsurance.map((insurance, index) => (
                      <div key={index} className="form-group d-flex align-items-center">
                        <img
                          src={insurance.logo}
                          alt={insurance.insurance_company}
                          style={{ width: "50px", height: "50px", marginRight: "10px", borderRadius: "30px" }}
                          className="mr-2"
                        />
                        <h6 className="mr-2">{insurance.insurance_company}</h6>
                        {/* <Button color="danger" size="sm" onClick={() => removeInsurance(index)}>
                        Remove
                      </Button> */}
                        <Icon name="cross-sm" onClick={() => removeInsurance(index)}></Icon>
                      </div>
                    ))}
                  </div>
                </Col>
              </div>
              <Col md="12" className="mt-4 d-flex justify-content-end">
                <Button type="submit" color="primary">
                  Update Facility
                </Button>
              </Col>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditFacility;
