import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import avatar from "../../../src/images/empty/default-img.jpg";
import axios from "axios";

const DepartmentDetails = ({ modal, closeModal, detailsId }) => {
  const [departmentDetails, setDepartmentDetails] = useState([]);

  useEffect(() => {
    departmentFetchData();
  }, [detailsId]);

  const departmentFetchData = async () => {
    const USR_TOKEN = localStorage.getItem("userToken");

    try {
      const response = await axios.get(
        `https://api.hospitalsinuae.com/api/resource/Hospital%20Department/${detailsId}`,
        {
          headers: { Authorization: USR_TOKEN, "content-type": "application/json" },
        }
      );
      setDepartmentDetails(response.data.data);
    } catch (error) {
      console.log("this is error", error);
    }
  };
  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <div class="nk-block mt-2">
            <div class="row g-gs">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-inner-group">
                    <div class="row mt-4">
                      <div class="col-md-5">
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-avatar lg">
                              <img
                                src={
                                  departmentDetails.head_image
                                    ? "https://api.hospitalsinuae.com" + departmentDetails.head_image
                                    : avatar
                                }
                                alt=""
                              />
                            </div>
                            <div class="user-info">
                              <div class="badge bg-light rounded-pill ucap">{departmentDetails.department_head}</div>
                              {/* <h5>Department of Cardiology</h5>
                                                            <span class="sub-text">Danat Al Emarat Hospital For Women & Children</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-7">
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            {/* <div class="user-avatar lg bg-primary">
                                                            <img src="./images/avatar/b-sm.jpg" alt="">
                                                        </div> */}
                            <div class="user-info">
                              {/* <div class="badge bg-light rounded-pill ucap">Dr. med. Neerod Kumar Jha</div>  */}
                              <h5>Department of {departmentDetails.department_name}</h5>
                              <span>Specialized in: {departmentDetails.department_name}</span>
                              <span class="sub-text">{departmentDetails.hospital_name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-inner">
                      <h6 class="overline-title mb-2">Details</h6>
                      <div class="row">
                        <div class="col-sm-12 col-md-4 col-xl-12">
                          <p
                            className="text-justify"
                            dangerouslySetInnerHTML={{ __html: departmentDetails.department_details }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default DepartmentDetails;
