import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Form, Label, Input } from "reactstrap";
import { Icon, Col, Button } from "../../components/Component";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ReactQuill from "react-quill";

const EditDepartment = ({ modal2, toggle, closeEditModal, editdetailId, data }) => {
  const [facilityData, setFacilityData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedCategoryLogo, setSelectedCategoryLogo] = useState(null);
  const [selectedDoctorImage, setSelectedDoctorImage] = useState(null);

  useEffect(() => {
    getFacilityList();
    getDoctorList();
    if (editdetailId) {
      getDepartmentDetails(editdetailId);
    }
  }, [editdetailId]);

  const formik = useFormik({
    initialValues: {
      hospital_name: "",
      department_name: "",
      department_head: "",
      department_details: "",
      department_logo: "",
      head_image: "",
      status: "",
    },
    validationSchema: Yup.object({
      hospital_name: Yup.string().required("Hospital Name is required"),
    }),
    onSubmit: async (values) => {
      const USR_TOKEN = localStorage.getItem("userToken");
      try {
        const response = await axios.put(
          `https://api.hospitalsinuae.com/api/resource/Hospital%20Department/${editdetailId}`,
          values,
          {
            headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
          }
        );
        console.log(response.data);
        data();
        closeEditModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getFacilityList = async () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    const USR_MAIL = JSON.parse(localStorage.getItem("user")).uid;

    try {
      const response = await axios.get(
        `https://api.hospitalsinuae.com/api/resource/Facility?fields=["facility_name","facility_type"]&filters=[["owner","=","${USR_MAIL}"]]`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      );
      setFacilityData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDoctorList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    const USR_MAIL = JSON.parse(localStorage.getItem("user")).uid;
    axios
      .get(
        `https://api.hospitalsinuae.com/api/resource/Doctors?fields=["first_name","last_name","profile_image"]&filters=[["owner","=","${USR_MAIL}"]]`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        const doctors = response.data.data.map((doctor) => ({
          fullName: `${doctor.first_name} ${doctor.last_name}`,
          ...doctor,
        }));
        setDoctorsList(doctors);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getDepartmentDetails = async (editdetailId) => {
    const USR_TOKEN = localStorage.getItem("userToken");
    try {
      const response = await axios.get(
        `https://api.hospitalsinuae.com/api/resource/Hospital%20Department/${editdetailId}`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      );
      const data = response.data.data;
      formik.setValues({
        hospital_name: data.hospital_name,
        department_name: data.department_name,
        department_head: data.department_head,
        department_details: data.department_details,
        department_logo: data.department_logo,
        status: data.status,
      });
      setSelectedCategoryLogo(data.department_logo);
      const selectedDoctor = doctorsList.find((doctor) => doctor.fullName === data.department_head);
      if (selectedDoctor) {
        setSelectedDoctorImage(selectedDoctor.profile_image);
      }
    } catch (error) {
      console.error(error);
    }
  };

  

  const getDepartmentCategoryList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    axios
      .get(
        `https://api.hospitalsinuae.com/api/resource/Department%20Category?fields=["name","department_name","logo"]`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setCategoryList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDepartmentCategoryList();
  }, []);

  const handleDepartmentChange = (e) => {
    const selectedDepartmentName = e.target.value;
    formik.handleChange(e);
    const selectedCategory = categoryList.find((category) => category.name === selectedDepartmentName);
    if (selectedCategory) {
      setSelectedCategoryLogo(selectedCategory.logo);
      formik.setFieldValue("department_logo", selectedCategory.logo);
    } else {
      setSelectedCategoryLogo(null);
      formik.setFieldValue("department_logo", "");
    }
  };

  const handleDoctorChange = (e) => {
    formik.handleChange(e);
    const selectedDoctor = doctorsList.find((doctor) => doctor.fullName === e.target.value);
    if (selectedDoctor) {
      setSelectedDoctorImage(selectedDoctor.profile_image);
      formik.setFieldValue("head_image", selectedDoctor.profile_image);
    } else {
      setSelectedDoctorImage(null);
      formik.setFieldValue("head_image", "");
    }
  };

  return (
    <Modal isOpen={modal2} toggle={closeEditModal} size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeEditModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Edit Department</h5>
          <form onSubmit={formik.handleSubmit}>
            <div className="row mt-4">
              <Col md="6">
                <div className="form-group">
                  <Label htmlFor="hospital_name" className="form-label">
                    Hospital
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="select"
                      name="hospital_name"
                      id="hospital_name"
                      value={formik.values.hospital_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Hospital</option>
                      {facilityData.map((facility) => (
                        <option key={facility.facility_name} value={facility.facility_name}>
                          {facility.facility_name}
                        </option>
                      ))}
                    </Input>
                    {formik.touched.hospital_name && formik.errors.hospital_name ? (
                      <div className="text-danger">{formik.errors.hospital_name}</div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <Label htmlFor="department_name" className="form-label">
                    Department
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="select"
                      name="department_name"
                      id="department_name"
                      value={formik.values.department_name}
                      onChange={handleDepartmentChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Department</option>
                      {categoryList.map((category) => (
                        <option key={category.name} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </Input>
                    {formik.touched.department_name && formik.errors.department_name ? (
                      <div className="text-danger">{formik.errors.department_name}</div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md="2">
                {selectedCategoryLogo && (
                  <div className="mt-2">
                    <img
                      src={"https://api.hospitalsinuae.com/" + selectedCategoryLogo}
                      alt="Department Logo"
                      style={{ maxWidth: "100px", height: "80px" }}
                    />
                  </div>
                )}
              </Col>
              <Col md="6" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="department_head" className="form-label">
                    Department Head
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="select"
                      name="department_head"
                      id="department_head"
                      value={formik.values.department_head}
                      onChange={handleDoctorChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Doctor</option>
                      {doctorsList.map((doctor) => (
                        <option key={doctor.fullName} value={doctor.fullName}>
                          {doctor.fullName}
                        </option>
                      ))}
                    </Input>
                    {formik.touched.department_head && formik.errors.department_head ? (
                      <div className="text-danger">{formik.errors.department_head}</div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md="6" className="mt-4">
                {selectedDoctorImage ? (
                  <div className="mt-2">
                    <img
                      src={"https://api.hospitalsinuae.com" + selectedDoctorImage}
                      alt="Doctor Profile"
                      style={{ maxWidth: "100px" }}
                    />
                  </div>
                ) : !formik.values.department_head ? (
                  ""
                ) : (
                  <h6 class="title text-center mt-5">No Image Found</h6>
                )}
              </Col>
              <Col md="12" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="department_details" className="form-label">
                    Department Details
                  </Label>
                  <div className="form-control-wrap">
                    <ReactQuill
                      theme="snow"
                      id="department_details"
                      name="department_details"
                      value={formik.values.department_details}
                      onChange={(content) => formik.setFieldValue("department_details", content)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.department_details && formik.errors.department_details ? (
                      <div className="text-danger">{formik.errors.department_details}</div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md="4" className="mt-4">
                <div className="form-group">
                  <Label htmlFor="status" className="form-label">
                    Status
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="12" className="mt-5 d-flex justify-content-end">
                <div className="form-group">
                  <Button color="primary" type="submit">
                    Update Department
                  </Button>
                </div>
              </Col>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditDepartment;
