import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { Label, Input } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewCard,
  Button,
} from "../components/Component";
import Success from "../components/popup/Success";
import ReactQuill from "react-quill";
import { getCurrentUser } from "../services/auth";

const AddDoctors = () => {
  const [loading, setLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [facilityData, setFacilityData] = useState([]);
  const [user, setUser] = useState(null);
  const [headPhysicianImg, setHeadPhysicianImg] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryLogo, setSelectedCategoryLogo] = useState(null);

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      localStorage.setItem("user", JSON.stringify(currentUser));
    } else {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      hospital: "",
      department: "",
      first_name: "",
      last_name: "",
      gender: "",
      designation: "",
      tel: "",
      specialist: "",
      location: "",
      profile_image: "",
      status: "",
      biography: "",
      education_and__professional_highlights: "",
      research_and_publications: "",
      industry_relationships: "",
    },
    validationSchema: Yup.object({
      hospital: Yup.string().required("hospital is required"),
    }),
    onSubmit: async (values) => {
      const USR_TOKEN = localStorage.getItem("userToken");
      try {
        const response = await axios.post("https://api.hospitalsinuae.com/api/resource/Doctors", values, {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        });
        console.log(response.data);
        setAlertSuccess(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const headPhysicianChange = async (e) => {
    const token = localStorage.getItem("userToken");
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const data = new FormData();
      data.append("file", selectedFile);
      setLoading(true);
      try {
        const response = await axios.post("https://api.hospitalsinuae.com/api/method/upload_file", data, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        });

        const Fileinfo = response.data.message.file_url;
        setHeadPhysicianImg(Fileinfo);
        formik.setFieldValue("profile_image", Fileinfo);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const getFacilityList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    if (user) {
      const USR_MAIL = user.uid;
      axios
        .get(
          `https://api.hospitalsinuae.com/api/resource/Facility?fields=["facility_name","facility_type"]&filters=[["owner","=","${USR_MAIL}"]]`,
          {
            headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          setFacilityData(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getDepartmentCategoryList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");

    axios
      .get(
        `https://api.hospitalsinuae.com/api/resource/Department%20Category?fields=["name","department_name","logo"]`,
        {
          headers: { Authorization: USR_TOKEN, "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setCategoryList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  useEffect(() => {
    getFacilityList();
    getDepartmentCategoryList();
  }, [user]);

  const handleDepartmentChange = (e) => {
    formik.handleChange(e);
    const selectedCategory = categoryList.find((category) => category.name === e.target.value);
    if (selectedCategory) {
      setSelectedCategoryLogo(selectedCategory.logo);
      formik.setFieldValue("department_logo", selectedCategory.logo);
    } else {
      setSelectedCategoryLogo(null);
      formik.setFieldValue("department_logo", "");
    }
  };

  return (
    <React.Fragment>
      <Head title="Add Department" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add Doctor / Nurse
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <form onSubmit={formik.handleSubmit}>
              <div className="row mt-4">
                <Col md="6">
                  <div className="form-group">
                    <Label htmlFor="hospital" className="form-label">
                      Hospital
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="hospital"
                        id="hospital"
                        value={formik.values.hospital}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Hospital</option>
                        {facilityData.map((facility) => (
                          <option key={facility.facility_name} value={facility.facility_name}>
                            {facility.facility_name}
                          </option>
                        ))}
                      </Input>
                      {formik.touched.hospital_name && formik.errors.hospital_name ? (
                        <div className="text-danger">{formik.errors.hospital_name}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <Label htmlFor="department" className="form-label">
                      Department
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="department"
                        id="department"
                        value={formik.values.department}
                        onChange={handleDepartmentChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Department</option>
                        {categoryList.map((category) => (
                          <option key={category.name} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </Input>
                      {formik.touched.department && formik.errors.department ? (
                        <div className="text-danger">{formik.errors.department}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="2">
                  {selectedCategoryLogo && (
                    <div className="mt-2">
                      <img
                        src={"https://api.hospitalsinuae.com/" + selectedCategoryLogo}
                        alt="Department Logo"
                        style={{ maxWidth: "100px", height: "80px" }}
                      />
                    </div>
                  )}
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="first_name" className="form-label">
                      First Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter First Name"
                      />
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="text-danger">{formik.errors.first_name}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="last_name" className="form-label">
                      Last Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Last Name"
                      />
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="text-danger">{formik.errors.last_name}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="gender" className="form-label">
                      Gender
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="gender"
                        id="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </Input>
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="designation" className="form-label">
                      Designation
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="designation"
                        name="designation"
                        value={formik.values.designation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Designation"
                      />
                      {formik.touched.designation && formik.errors.designation ? (
                        <div className="text-danger">{formik.errors.designation}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="tel" className="form-label">
                      Tele
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="tel"
                        name="tel"
                        value={formik.values.tel}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Tel"
                      />
                      {formik.touched.tel && formik.errors.tel ? (
                        <div className="text-danger">{formik.errors.tel}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="specialist" className="form-label">
                      Specialist
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="specialist"
                        name="specialist"
                        value={formik.values.specialist}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Specialist"
                      />
                      {formik.touched.specialist && formik.errors.specialist ? (
                        <div className="text-danger">{formik.errors.specialist}</div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="location" className="form-label">
                      Location
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="location"
                        name="location"
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Location"
                      />
                      {/* {formik.values.location && (
                      <div className="mt-4">
                        <iframe
                          src={formik.values.location}
                          width="900"
                          height="350"
                          frameborder="0"
                          style={{ border: 0 }}
                          allowfullscreen=""
                          aria-hidden="false"
                          tabindex="0"
                        ></iframe>
                      </div>
                    )} */}
                    </div>
                  </div>
                </Col>
                <Col sm="6" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="profile_image" className="form-label">
                      Profile Photo
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-file">
                        <Input type="file" id="profile_image" name="profile_image" onChange={headPhysicianChange} />
                      </div>
                    </div>
                    {loading ? (
                      <h6 class="title text-center mt-2">Loading.....</h6>
                    ) : (
                      <>
                        {headPhysicianImg && (
                          <div className="mt-3">
                            <img
                              src={`https://api.hospitalsinuae.com${headPhysicianImg}`}
                              alt="Department Head"
                              width="100"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Col>
                <Col md="4" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="status" className="form-label">
                      Status
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Input>
                    </div>
                  </div>
                </Col>
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Biography
                    </Label>
                    <ReactQuill
                      value={formik.values.biography}
                      onChange={(content) => formik.setFieldValue("biography", content)}
                      placeholder="Enter Biography"
                    />
                  </div>
                </Col>
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Education & Professional Highlights
                    </Label>
                    <ReactQuill
                      value={formik.values.education_and__professional_highlights}
                      onChange={(content) => formik.setFieldValue("education_and__professional_highlights", content)}
                      placeholder="Enter Education & Professional Highlights"
                    />
                  </div>
                </Col>
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Research & Publications
                    </Label>
                    <ReactQuill
                      value={formik.values.research_and_publications}
                      onChange={(content) => formik.setFieldValue("research_and_publications", content)}
                      placeholder="Enter Research & Publications"
                    />
                  </div>
                </Col>
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <Label htmlFor="department_head" className="form-label">
                      Industry Relationships
                    </Label>
                    <ReactQuill
                      value={formik.values.industry_relationships}
                      onChange={(content) => formik.setFieldValue("industry_relationships", content)}
                      placeholder="Enter Industry Relationships"
                    />
                  </div>
                </Col>
              </div>
              <Col md="12" className="mt-5 d-flex justify-content-end">
                <Button type="submit" color="primary">
                  Add Doctor
                </Button>
              </Col>
            </form>
          </PreviewCard>
        </Block>
      </Content>
      <Success show={alertSuccess} url={"/doctors"} />
    </React.Fragment>
  );
};

export default AddDoctors;
