import React, { useContext, useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import Empty from "../images/empty/no-image.jpg";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon, Button } from "../components/Component";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import hospitalimg from "../images/product/hospital.jpg";

const FacilityDetails = () => {
  const [sm, updateSm] = useState(false);
  const [hospitalDetails, setHospitalDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  let { hospitalId } = useParams();

  useEffect(() => {
    hospitalFetchData();
  }, [hospitalId]);

  const hospitalFetchData = async () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    setLoading(true);
    try {
      const response = await axios.get(`https://api.hospitalsinuae.com/api/resource/Facility/${hospitalId}`, {
        headers: { Authorization: USR_TOKEN, "content-type": "application/json" },
      });
      setHospitalDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log("this is error", error);
    }
  };

  return (
    <React.Fragment>
      <Head title="Facilities"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Facility Details
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Link to={`${process.env.PUBLIC_URL}/facilities`}>
                        <Button color="primary" className="btn-icon p-1" /* onClick={() => setModal({ add: true })} */>
                          <Icon name="arrow-left"></Icon>
                          Go Back
                        </Button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {loading ? (
          <h6 class="title text-center">Loading.....</h6>
        ) : (
          <Block>
            <div className="nk-block">
              <div className="card">
                <div className="card-inner">
                  <div className="row pb-5">
                    <div className="col-md-4">
                      <div className=" me-xl-1 me-xxl-5">
                        <h6 className="product-title text-success">{hospitalDetails.facility_type}</h6>
                        <span
                          className={`badge mb-2 badge-dim bg-${
                            hospitalDetails.status === "Approved"
                              ? "success"
                              : hospitalDetails.status === "Pending Review"
                              ? "warning"
                              : "danger"
                          }`}
                        >
                          {/* <em className="icon ni ni-check-thick"></em>&nbsp; */}
                          {hospitalDetails.status}
                        </span>
                      </div>
                      <div class="slider-item rounded mt-3">
                        <img
                          src={
                            hospitalDetails.image_1 ? hospitalDetails.image_1 : Empty
                          }
                          class="rounded w-100 h-100"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-5">
                      <div className="product-info mt-5 me-xxl-5">
                        <h4 className="product-title">{hospitalDetails.facility_name}</h4>
                        <div className="product-meta">
                          <ul className="d-flex g-3 gx-5">
                            <li>
                              <div className="fs-15px  text-secondary">
                                <em className="icon ni ni-map-pin-fill"></em> Address : {hospitalDetails.location}
                              </div>
                            </li>
                          </ul>
                          <ul className="d-flex g-3 gx-5">
                            <li>
                              <div className="fs-15px text-secondary">
                                <em className="icon ni ni-call-fill"></em> {hospitalDetails.contact}
                              </div>
                            </li>
                            <li>
                              <div className="fs-15px text-secondary">
                                <em className="icon ni ni-whatsapp-round"></em> {hospitalDetails.contact}
                              </div>
                            </li>
                          </ul>
                          <ul className="d-flex g-3 gx-5">
                            {hospitalDetails.email && (
                              <li>
                                <div className="fs-15px text-secondary">
                                  <em className="icon ni ni-mail"></em> {hospitalDetails.email}
                                </div>
                              </li>
                            )}
                            {hospitalDetails.website && (
                              <li>
                                <div className="fs-15px text-secondary">
                                  <em className="icon ni ni-globe"></em> {hospitalDetails.website}
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 mt-5">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126743.96836772808!2d-122.419416!3d37.774929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858064b74c3b59%3A0x7b0c27e2e9f45d51!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1596650737203!5m2!1sen!2s"
                        width="140"
                        height="140"
                        frameborder="0"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                        className="mt-5"
                      ></iframe>
                    </div>
                  </div>
                  <hr className="hr border-light" />
                </div>
              </div>
            </div>
          </Block>
        )}

        {/* <AddModal
          modal={modal.add}
          closeModal={closeModal}
          filterStatus={filterStatus}
        />
        <EditModal
          modal={modal.edit}
          closeModal={closeEditModal}
          filterStatus={filterStatus}
        /> */}
      </Content>
    </React.Fragment>
  );
};
export default FacilityDetails;
